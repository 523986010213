import styles from './Tuner.module.scss'
import { instruments } from '../instruments'
import { useState, useEffect } from 'react'
import TuningSection from './TuningSection'
import Indicator from '../components/Indicator'
import Instruction from './Instruction'
import useTranslation from '../translations/useTranslation'
import Instrument from './Instrument'
import Tuner from '../tuner'
import * as Sentry from "@sentry/node"

let tunerInstance = null
let autoTuneWasOn = false
let noSoundTimeout = null

function isCorrectTuning(note) {
  return Math.abs(note.cents) <= 5
}

export default function TunerComponent(props) {
  const [instrument] = useState(props.instrument)
  const currentInstrument =
    instruments.find((inst) => inst.name === instrument) || {}

  // work regimes
  const [autoTuneActivated, setAutoTuneActivated] = useState(false)
  const [shouldDetectString, setShouldDetectString] = useState(true)
  const [shouldLoopNote, setLoopNote] = useState(true)
  const [manuallySelectedString, setManuallySelectedString] = useState(-1)
  const [delayActive, setDelayActive] = useState(false)
  const [note, setNote] = useState({})
  const { t } = useTranslation()

  useEffect(() => {
    // on each rerender, update references to functions
    window.onblur = maybePauseAutoTune
    window.onpopstate = stopAutoTune
    window.onfocus = maybeRestartAutotune
  })

  useEffect(() => {
    updateNote(note)
  }, [manuallySelectedString])

  const handleInitResult = (startedSuccessfully) => {
    console.log('started successfully:', startedSuccessfully)

    if (startedSuccessfully) setAutoTuneActivated(true)
    else {
      setAutoTuneActivated(false)
    }
  }

  const handleInitError = (err) => {
    if (err.name === 'NotAllowedError') {
      alert(t('error--MicInit--NotAllowed'))
    } else {
      alert(t('error--MicInit--Unknown'))
    }
    console.error(err)
    Sentry.captureException(err)
  }

  function updateNote(newRawNote) {
    const newNote = newRawNote
    let targetNote = null

    // just using two set functions to get upToDate value of manuallySelectedString
    setManuallySelectedString((latestManuallySelectedString) => {
      setShouldDetectString((shouldDetect) => {
        if (!shouldDetect && latestManuallySelectedString > -1)
          targetNote =
            currentInstrument.notes.values[latestManuallySelectedString]
        else {
          const closestNoteOnInstrument = Tuner.prototype.getClosestNote(
            currentInstrument.notes.values,
            newNote.frequency
          )
          targetNote = closestNoteOnInstrument
        }

        return shouldDetect
      })
      return latestManuallySelectedString
    })

    console.log('targetNote', targetNote)

    newNote.cents = Tuner.prototype.getCents(newNote.frequency, targetNote)
    newNote.octave = Tuner.prototype.getOctave(targetNote)
    newNote.targetNoteValue = targetNote
    setNote(newNote)

    clearTimeout(noSoundTimeout)
    noSoundTimeout = setTimeout(() => {
      setNote({})
    }, 1000)
  }

  const startAutoTune = () => {
    function noteDetectedCallback(newNote) {
      updateNote(newNote)
    }

    if (!tunerInstance) tunerInstance = new Tuner()
    tunerInstance.init(handleInitResult, handleInitError, noteDetectedCallback)
  }

  const maybeRestartAutotune = () => {
    if (autoTuneWasOn) {
      autoTuneWasOn = false
      startAutoTune()
    }
  }

  const maybePauseAutoTune = () => {
    if (autoTuneActivated) {
      autoTuneWasOn = true
      stopAutoTune()
    }
  }

  const stopAutoTune = () => {
    if (tunerInstance) {
      console.log('stopping tuner instance')
      tunerInstance.stop()
    }
    setAutoTuneActivated(false)
  }

  const activateListeningDelay = () => {
    // this ensures that the tuner doesn't pick up its own sound
    setDelayActive(true)
    setTimeout(() => {
      setDelayActive(false)
    }, 3500)
  }

  const handleMicrophoneSwitch = (shouldUseMicrophone) => {
    if (shouldUseMicrophone) startAutoTune()
    else stopAutoTune()
  }

  const handleAutoDetectSwitch = (shouldAutoDetect) => {
    setShouldDetectString(shouldAutoDetect)
  }

  const handleLoopNoteSwitch = (shouldLoop) => {
    setLoopNote(shouldLoop)
  }

  const handleManualStringPick = (stringIdx) => {
    console.log(
      'Set current note to',
      currentInstrument.notes.values[stringIdx]
    )
    setManuallySelectedString(stringIdx)
    setShouldDetectString(false)
  }

  const hearSomething = Boolean(note.targetNoteValue && !delayActive)
  const noteCorrectlyTuned = isCorrectTuning(note) && hearSomething

  return (
    <div className={styles.container}>
      <div className={styles.instrumentContainer}>
        <h1>{t(currentInstrument.pageHeadingKey)}</h1>
        <div className={styles.indicatorContainer}>
          <Instruction
            visible={!hearSomething}
            mode={autoTuneActivated ? t('usingMicrophone') : t('tuningByEar')}
            message={autoTuneActivated ? t('listening') : t('tapTheNoteOnce')}
          />
          <Indicator
            visible={autoTuneActivated && hearSomething}
            note={note}
            hearSomething={hearSomething}
            noteCorrectlyTuned={noteCorrectlyTuned}
          />
        </div>
        <Instrument
          activateListeningDelay={activateListeningDelay}
          handleStringChan
          hearSomething={hearSomething}
          isAutoTuneActivated={autoTuneActivated}
          instrument={instrument}
          manuallySelectedString={manuallySelectedString}
          note={note}
          noteCorrectlyTuned={noteCorrectlyTuned}
          onManualStringPick={handleManualStringPick}
          shouldDetectString={shouldDetectString}
          shouldLoopNote={shouldLoopNote}
        />
      </div>
      <div className={styles.tuningSectionContainer}>
        <TuningSection
          autoDetect={shouldDetectString}
          isAutoTuneActivated={autoTuneActivated}
          hearSomething={hearSomething}
          onMicrophoneSwitch={handleMicrophoneSwitch}
          onAutoDetectSwitch={handleAutoDetectSwitch}
          onLoopNoteSwitch={handleLoopNoteSwitch}
          note={note}
          noteCorrectlyTuned={noteCorrectlyTuned}
          instrument={instrument}
        />
      </div>
    </div>
  )
}
