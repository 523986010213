import React from 'react'
import styles from './Switch.module.scss'

function Switch({ active, disabled, label, onChange }) {
  const wrapperClasses = [styles.switchWrapper]
  if (active) wrapperClasses.push(styles.switchWrapperActive)
  if (disabled) wrapperClasses.push(styles.switchDisabled)

  const handleChange = React.useCallback(() => {
    if (!disabled) onChange()
  }, [disabled, onChange])

  return (
    <div className={wrapperClasses.join(' ')} onClick={handleChange}>
      <div className={styles.switchBody}>
        <div className={styles.switchCircle}></div>
      </div>
      <span>{label}</span>
    </div>
  )
}

function Connection() {
  return <div className={styles.connectingLine}></div>
}

Switch.Connection = Connection

export default Switch
