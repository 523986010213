import React, { useState, useEffect } from 'react'
import styles from './TuningSection.module.scss'
import SelectTuning from './SelectTuning'
import Switch from './Switch'
import useTranslation from '../translations/useTranslation'
import Indicator from '../components/Indicator'
import Instruction from './Instruction'
import ModeSwitch from './ModeSwitch'

function ControlPanel(props) {
  return <div className={styles.controlPanel}>{props.children}</div>
}

export default function TuningSection(props) {
  const { isAutoTuneActivated, hearSomething, noteCorrectlyTuned, note, instrument } = props
  const [useMicrophone, setUseMicrophone] = useState(false)
  const [autoDetect, setAutoDetect] = useState(props.autoDetect)
  const [loopNote, setLoopNote] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    if (props.autoDetect !== autoDetect) setAutoDetect(props.autoDetect)
  }, [props.autoDetect])

  function handleByEar() {
    setUseMicrophone(false)
    props.onMicrophoneSwitch(false)
  }

  function handleAutoTune() {
    setUseMicrophone(true)
    props.onMicrophoneSwitch(true)
  }

  function onAutoDetectSwitch(e) {
    setAutoDetect((prevAutoDetect) => {
      console.log('auto-detect switched to', !prevAutoDetect)
      props.onAutoDetectSwitch(!prevAutoDetect)
      return !prevAutoDetect
    })
  }

  function onLoopNoteSwitch() {
    setLoopNote((prevLoopNote) => {
      console.log('loop-note switched to', !prevLoopNote)
      props.onLoopNoteSwitch(!prevLoopNote)
      return !prevLoopNote
    })
  }


  return (
    <div className={styles.container}>
      <div className={styles.indicatorContainer}>
        <Instruction
          visible={!hearSomething}
          mode={isAutoTuneActivated ? t('usingMicrophone') : t('tuningByEar')}
          message={isAutoTuneActivated ? t('listening') : t('tapTheNoteOnce')}
        />
        <Indicator
          visible={isAutoTuneActivated && hearSomething}
          note={note}
          hearSomething={hearSomething}
          noteCorrectlyTuned={noteCorrectlyTuned}
        />
      </div>
      <ControlPanel>
        <ModeSwitch
          microphone={useMicrophone && isAutoTuneActivated}
          handleByEar={handleByEar}
          handleAutoTune={handleAutoTune}
        />
        <div style={{ marginBottom: 24 }}>
          {useMicrophone && (
            <Switch
              active={autoDetect}
              label={t('detectString')}
              onChange={onAutoDetectSwitch}
              disabled={!useMicrophone}
            />
          )}
          {!useMicrophone && (
            <Switch
              active={loopNote}
              label={t('loopNote')}
              onChange={onLoopNoteSwitch}
              disabled={useMicrophone}
            />
          )}
        </div>
        <SelectTuning currentInstrument={instrument} />
      </ControlPanel>
    </div>
  )
}
