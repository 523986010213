import styles from './Instruction.module.scss'

export default function Instruction(props) {

  const classes = [styles.container]
  if (props.visible) classes.push(styles.visible)

  return <div className={classes.join(' ')}>
    <p className={styles.dimmedHeading}>{props.mode}</p>
    <p className={styles.mainInstruction}>{props.message}</p>
    <div className={styles.highlighter}></div>
  </div>
}
