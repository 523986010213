import styles from "./ChordChordBanner.module.scss";

export default function ChordChordBanner() {
  return (
    <section className={styles.container}>
      <div className={styles.banner}>
        <div className={styles.body}>
          <h2 className={styles.title}>
            Learn To Write <b>Chord Progressions</b> 
            <br />
            <span>
              <span>
                <b>That Make Hits!</b>
              </span>
            </span>
          </h2>
          <a
            href="https://secrets.chordchord.com/viral-chord-progressions?utm_source=guitar-tuner&utm_medium=banner&utm_campaign=viral-chord-progressions&utm_content=banner-01"
            target="_blank"
          >
            <img
              src="/chordchord-secrets-video.jpg"
              alt="ChordChord Secrets"
              className={styles.imgCenter}
            />
          </a>
          <a
            href="https://secrets.chordchord.com/viral-chord-progressions?utm_source=guitar-tuner&utm_medium=banner&utm_campaign=viral-chord-progressions&utm_content=banner-01"
            className={styles.btn}
            target="_blank"
          >
            <span>WATCH FREE VIDEO</span>
          </a>
          {/* <div className={styles.name}>ChordChord</div> */}
        </div>
      </div>
    </section>
  );
}
