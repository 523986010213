import styles from './Note.module.scss'

export default function Note(props) {
  const { autoPlaying } = props
  const className = autoPlaying ? styles.noteAutoPlay : styles.note
  return (
    <button className={className} onClick={props.onClick}>
      {props.note}
    </button>
  )
}
