import styles from './Indicator.module.scss'
import Tick from '../public/tick.svg'
import IndicatorPointer from '../public/indicator-pointer.svg'
import useTranslation from '../translations/useTranslation'

const defaultTransform = 'translateX(-50%)'
const leftMostCentsVal = -100
const rightMostCentsVal = 100
const leftMostTranslateVal = -120
const rightMostTranslateVal = 120

export default function Indicator(props) {
  const { note, hearSomething, noteCorrectlyTuned, visible } = props
  const { t } = useTranslation()

  const tooLow = !noteCorrectlyTuned && note.cents < 0
  const tooHigh = !noteCorrectlyTuned && note.cents > 0

  const translateValue = mapValueFromRangeToRange(
    note.cents,
    leftMostCentsVal,
    rightMostCentsVal,
    leftMostTranslateVal,
    rightMostTranslateVal
  )
  let transform = defaultTransform
  if (hearSomething) transform += ` translateX(${translateValue}px)`  

  const containerClasses = [styles.container]
  if (noteCorrectlyTuned) containerClasses.push('correct')
  if (visible) containerClasses.push(styles.visible)

  return (
    <div className={containerClasses.join(' ')}>
      <div className={styles.pointerContainer} style={{ transform }}>
        {!noteCorrectlyTuned && <span>{note.cents || ''}</span>}
        {noteCorrectlyTuned && <Tick className={styles.tick} />}
        <IndicatorPointer
          className={
            noteCorrectlyTuned ? styles.pointerCorrect : styles.pointer
          }
        />
      </div>
      <div className={styles.bar}>
        {tooLow && <div className={styles.low}>{t('tooLow')}</div>}
        <div
          className={noteCorrectlyTuned ? styles.middleCorrect : styles.middle}
        ></div>
        {tooHigh && <div className={styles.high}>{t('tooHigh')}</div>}
      </div>
    </div>
  )
}

function mapValueFromRangeToRange(
  value,
  inputRangeMin,
  inputRangeMax,
  outputRangeMin,
  outputRangeMax
) {
  let filteredValue = value
  if (value < inputRangeMin) filteredValue = inputRangeMin
  if (value > inputRangeMax) filteredValue = inputRangeMax
  // Figure out how 'wide' each range is
  const inputSpan = inputRangeMax - inputRangeMin
  const outputSpan = outputRangeMax - outputRangeMin

  // Convert the left range into a 0-1 range (float)
  const valueScaled =
    parseFloat(filteredValue - inputRangeMin) / parseFloat(inputSpan)

  // Convert the 0-1 range into a value in the right range.
  return outputRangeMin + valueScaled * outputSpan
}
