import React from 'react'
import styles from './BigInstrumentSelector.module.scss'
import { instruments } from '../instruments'
import useTranslation from '../translations/useTranslation'

export default function BigInstrumentSelector() {
  const { locale, t } = useTranslation()

  const localeizedUrl = (instrumentName) => {
    if (locale !== 'en') return `/${locale}/${instrumentName}-tuning`
    else return `/${instrumentName}-tuning`
  }

  return (
    <div className={styles.container}>
      {instruments.map((instrument) => (
        <Instrument
          key={instrument.name}
          buttonText={t(`tune_${instrument.displayNameKey}`)}
          name={t(instrument.displayNameJSXKey)}
          url={localeizedUrl(instrument.name)}
          Image={instrument.largeImage}
        />
      ))}
    </div>
  )
}

function Instrument({ name, url, Image, buttonText }) {
  return (
    <div className={styles.instrument}>
      <a href={url}>
        <Image className={styles.instrumentImage} />
      </a>
      <h2 className={styles.instrumentName}>{name}</h2>
      <a href={url} className={styles.instrumentButton}>
        {buttonText}
      </a>
    </div>
  )
}
