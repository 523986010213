import { useRef, useState, useEffect } from 'react'
import styles from './Instrument.module.scss'
import Note from '../components/Note'
import { animatePlayedNote, stopNoteAnimation } from '../helpers'
import { Howl } from 'howler'
import { instruments } from '../instruments'

function getNotesStyleClass(instrumentName) {
  return styles[`notes-${instrumentName}`]
}

const getSoundsList = (instrument) => {
  return instrument.notes.sounds.map((noteSound) => {
    return new Howl({
      src: [noteSound],
    })
  })
}

let scheduledMarkTuned = null
let scheduledMarkTunedAbort = null
let stringSounds = []
let markTunedSound = null
let autoPlayInterval = null

export default function Instrument(props) {
  const {
    activateListeningDelay,
    hearSomething,
    instrument,
    isAutoTuneActivated,
    manuallySelectedString,
    note,
    noteCorrectlyTuned,
    shouldDetectString,
    shouldLoopNote,
  } = props

  const currentInstrument =
    instruments.find((inst) => inst.name === instrument) || {}

  const initialTunedStringsValue = currentInstrument
    ? Array(currentInstrument.notes.values.length).fill(false)
    : []

  const [tunedStrings, setTunedStrings] = useState(initialTunedStringsValue)
  const [loopingNote, setLoopingNote] = useState(null)

  const svgContainer = useRef(null)
  const notesContainer = useRef(null)

  useEffect(() => {
    if (isAutoTuneActivated || !shouldLoopNote) stopLoopingNote(true)
  }, [isAutoTuneActivated, shouldLoopNote])

  useEffect(() => abortMarkTuned(), [note.targetNoteValue])

  useEffect(() => {
    // load sound just once
    markTunedSound = new Howl({
      src: '/sounds/positive-alert.mp3',
    })
  }, [])

  useEffect(() => {
    // preload instrument sounds
    stringSounds = getSoundsList(currentInstrument)

    // stop playing string
    stopLoopingNote(true)
  }, [currentInstrument.name])

  const handleNoteClick = (noteIdx) => {
    activateListeningDelay()
    if (!isAutoTuneActivated) {
      if (shouldLoopNote) {
        // nothing on loop now
        if (autoPlayInterval === null) {
          loopNote(noteIdx)

          // something on loop, stop it
        } else {
          stopLoopingNote()
          if (noteIdx !== loopingNote) {
            loopNote(noteIdx)
          } else {
            setLoopingNote(null)
          }
        }
      } else {
        playNote(noteIdx)
      }
    } else if (isAutoTuneActivated) {
      playNote(noteIdx)
      props.onManualStringPick(noteIdx)
    }
  }

  const loopNote = (noteIdx) => {
    playNote(noteIdx)
    setLoopingNote(noteIdx)
    autoPlayInterval = setInterval(() => playNote(noteIdx), 2000)
  }

  const stopLoopingNote = (force) => {
    clearInterval(autoPlayInterval)
    autoPlayInterval = null
    if (force) setLoopingNote(null)
  }

  const playNote = (noteIdx) => {
    // sound
    stringSounds[noteIdx].play()
    stringSounds[noteIdx].fade(1, 0, 2500)

    //animation
    animateNote(noteIdx)
  }

  const markStringTuned = (stringIndex) => {
    markTunedSound.play()
    console.log('manual string', manuallySelectedString)
    if (shouldDetectString || manuallySelectedString > -1) {
      // TODO: looksweird, figure out this code
      animateTuned(stringIndex)
    }
    setTunedStrings((tunedStrings) => {
      const newTunedStrings = [...tunedStrings]
      newTunedStrings[stringIndex] = true
      return newTunedStrings
    })
  }

  const abortMarkTuned = () => {
    clearTimeout(scheduledMarkTuned)
    scheduledMarkTuned = null
  }

  const keepMarkTunedScheduled = () => {
    clearTimeout(scheduledMarkTunedAbort)
    scheduledMarkTunedAbort = null
  }

  const animateNote = (noteIdx) => {
    const svgEl = svgContainer.current.querySelector('svg')
    animatePlayedNote(svgEl, currentInstrument.name, noteIdx)
  }

  const animateTuned = (noteIdx) => {
    const noteButton = notesContainer.current.children[noteIdx]
    noteButton.onanimationend = (event) => {
      event.target.style.animation = 'none'
    }
    noteButton.style.animation = 'bounce-on-tuned 0.5s forwards ease-in-out'
  }

  const InstrumentImage = currentInstrument.largeImage
  const notesClass = getNotesStyleClass(currentInstrument.name)

  const currentStringIndex = shouldDetectString
    ? currentInstrument.notes.values.indexOf(note.targetNoteValue)
    : manuallySelectedString

  noteCorrectlyTuned ? keepMarkTunedScheduled() : abortMarkTuned()

  // if met correct note after incorrect notes (or different correct note)
  if (noteCorrectlyTuned && scheduledMarkTuned === null) {
    // mark string tuned in 1s
    scheduledMarkTuned = setTimeout(
      () => markStringTuned(currentStringIndex),
      1000
    )

    // unless kept scheduled within .5s, abort marking as tuned
    scheduledMarkTunedAbort = setTimeout(abortMarkTuned, 500)
  }

  const containerClasses = [styles.instrumentContainer]

  // highlight detected or selected string
  if (hearSomething) {
    containerClasses.push(`string-${currentStringIndex}`)

    // fix weird animation conflict when
    // a note is heard shortly after manual string pick
    const svgEl = svgContainer.current.querySelector('svg')
    stopNoteAnimation(svgEl, currentInstrument.name, currentStringIndex)
  }

  // keep manually selected string highlighted
  if (!shouldDetectString && manuallySelectedString > -1 && isAutoTuneActivated)
    containerClasses.push(`string-${manuallySelectedString}-manual`)

  tunedStrings.forEach((stringIsTuned, i) => {
    if (stringIsTuned) containerClasses.push(`string-tuned-${i}`)
  })

  return (
    <div className={containerClasses.join(' ')}>
      <div ref={svgContainer} className={styles.instrumentWrapper}>
        <InstrumentImage className={styles.instrument} />
        <div className={notesClass} ref={notesContainer}>
          {currentInstrument.notes.letters.map((note, i) => (
            <Note
              autoPlaying={loopingNote === i}
              key={note + '-' + currentInstrument + '-' + i}
              note={note}
              onClick={() => handleNoteClick(i)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
