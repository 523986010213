import Acoustic from '../public/Acoustic.svg'
import Electric from '../public/Electric.svg'
import Bass from '../public/Bass.svg'
import Ukulele from '../public/Ukulele-big.svg'

const instruments = [
  {
    displayNameJSX: <span>Acoustic<span className='xl-only'>&nbsp;Guitar</span></span>,
    displayName: 'Acoustic Guitar',
    displayNameKey: 'acoustic',
    displayNameJSXKey: 'acousticJSX',
    name: 'acoustic-guitar',
    img: '/acoustic_icon.png',
    largeImage: Acoustic,
    notes: {
      letters: ['E', 'A', 'D', 'G', 'B', 'E'],
      values: [40, 45, 50, 55, 59, 64],
      sounds: [
        '/sounds/acoustic_mp3/e2.mp3',
        '/sounds/acoustic_mp3/a2.mp3',
        '/sounds/acoustic_mp3/d3.mp3',
        '/sounds/acoustic_mp3/g3.mp3',
        '/sounds/acoustic_mp3/b3.mp3',
        '/sounds/acoustic_mp3/e4.mp3',
      ],
    },
    tunings: [
      ['Standard', 'E A D G B E'],
      ['Drop D', 'D A D G B E'],
      ['Modal D', 'D A D G A D'],
      ['Open C6', 'C G C G A E'],
    ],
    pageHeadingKey: 'onlineAcousticTuner',
  },
  {
    displayNameJSX: <span>Electric<span className='xl-only'>&nbsp;Guitar</span></span>,
    displayName: 'Electric Guitar',
    displayNameKey: 'electric',
    displayNameJSXKey: 'electricJSX',
    name: 'electric-guitar',
    img: '/electric_icon.png',
    largeImage: Electric,
    notes: {
      letters: ['E', 'A', 'D', 'G', 'B', 'E'],
      values: [40, 45, 50, 55, 59, 64],
      sounds: [
        '/sounds/electric_mp3/e2.mp3',
        '/sounds/electric_mp3/a2.mp3',
        '/sounds/electric_mp3/d3.mp3',
        '/sounds/electric_mp3/g3.mp3',
        '/sounds/electric_mp3/b3.mp3',
        '/sounds/electric_mp3/e4.mp3',
      ],
    },
    tunings: [
      ['Standard', 'E A D G B E'],
      ['Drop D', 'D A D G B E'],
      ['Modal D', 'D A D G A D'],
      ['Open C6', 'C G C G A E'],
    ],
    pageHeadingKey: 'onlineElectricTuner',
  },
  {
    displayNameJSX: <span>Bass<span className='xl-only'>&nbsp;Guitar</span></span>,
    displayName: 'Bass Guitar',
    displayNameKey: 'bass',
    displayNameJSXKey: 'bassJSX',
    name: 'bass-guitar',
    img: '/bass_icon.png',
    largeImage: Bass,
    notes: {
      letters: ['E', 'A', 'D', 'G'],
      values: [28, 33, 38, 43],
      sounds: [
        '/sounds/bass_mp3/e1.mp3',
        '/sounds/bass_mp3/a1.mp3',
        '/sounds/bass_mp3/d2.mp3',
        '/sounds/bass_mp3/g2.mp3',
      ],
    },
    tunings: [
      ['Standard', 'E A D G'],
      ['Drop D', 'D A D G'],
      ['Drop C', 'C A D G'],
      ['Full Step', 'D G C F'],
    ],
    pageHeadingKey: 'onlineBassTuner',
  },
  {
    displayNameJSX: <span>Ukulele</span>,
    displayName: 'Ukulele',
    displayNameKey: 'ukulele',
    displayNameJSXKey: 'ukuleleJSX',
    name: 'ukulele',
    img: '/ukulele_icon.png',
    largeImage: Ukulele,
    notes: {
      letters: ['G', 'C', 'E', 'A'],
      values: [67, 60, 64, 69],
      sounds: [
        '/sounds/ukulele_mp3/g4.mp3',
        '/sounds/ukulele_mp3/c4.mp3',
        '/sounds/ukulele_mp3/e4.mp3',
        '/sounds/ukulele_mp3/a4.mp3',
      ],
    },
    tunings: [
      ['Standard', 'G C E A'],
      ['Traditional', 'A D F# B'],
      ['Baritone', 'D G B E'],
      ['Canadian', 'A D F# B'],
    ],
    pageHeadingKey: 'onlineUkuleleTuner',
  },
]

export { instruments }
