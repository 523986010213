const ANIMATIONS = {
  'pulled-acoustic-guitar-string': [
    ['attributeName', 'fill'],
    ['restart', 'always'],
    ['dur', '4s'],
    ['keyTimes', '0;.1;1'],
    ['values', '#5f93f8; #5f93f8; #ebffff;'],
  ],
  'pulled-electric-guitar-string': [
    ['attributeName', 'stroke'],
    ['restart', 'always'],
    ['dur', '4s'],
    ['keyTimes', '0;.1;1'],
    ['values', '#5f93f8; #5f93f8; #fff']
  ],
  'pulled-bass-guitar-string': [
    ['attributeName', 'fill'],
    ['restart', 'always'],
    ['dur', '4s'],
    ['keyTimes', '0;.1;1'],
    ['values', '#5f93f8; #5f93f8; #fff']
  ],
  'pulled-acoustic-guitar-key': [
    ['attributeName', 'fill'],
    ['restart', 'always'],
    ['dur', '4s'],
    ['keyTimes', '0;.1;1'],
    ['values', '#5f93f8; #5f93f8; #e6e6e6']
  ],
  'pulled-electric-guitar-key': [
    ['attributeName', 'fill'],
    ['restart', 'always'],
    ['dur', '4s'],
    ['keyTimes', '0;.1;1'],
    ['values', '#5f93f8; #5f93f8; #DEDEDE']
  ],
  'pulled-ukulele-key': [
    ['attributeName', 'fill'],
    ['restart', 'always'],
    ['dur', '4s'],
    ['keyTimes', '0;.1;1'],
    ['values', '#5f93f8; #5f93f8; #272729']
  ],
  'pulled-ukulele-neck': [
    ['attributeName', 'fill'],
    ['restart', 'always'],
    ['dur', '4s'],
    ['keyTimes', '0;.1;1'],
    ['values', '#5f93f8; #5f93f8; #e6e6e6']
  ]
}

ANIMATIONS['pulled-ukulele-string'] = ANIMATIONS['pulled-acoustic-guitar-string']
ANIMATIONS['pulled-bass-guitar-key'] = ANIMATIONS['pulled-electric-guitar-key']
ANIMATIONS['pulled-acoustic-guitar-neck'] = ANIMATIONS['pulled-acoustic-guitar-key']
ANIMATIONS['pulled-electric-guitar-neck'] = ANIMATIONS['pulled-eletric-guitar-key']
ANIMATIONS['pulled-bass-guitar-neck'] = ANIMATIONS['pulled-bass-guitar-key']

export default ANIMATIONS