import React, { useEffect } from 'react'
import { useRouter } from 'next/dist/client/router'
import { getInitialLocale } from './translations/getInitialLocale'
import { instruments } from './instruments'
import INSTRUMENT_ANIMATIONS from './instruments/animations'

function usePreloadedInstrumentImages() {
  useEffect(() => {
    for (let instrument of instruments) {
      const image = new Image()
      image.src = instrument.largeImage
    }
  }, [])
}

function animatePlayedNote(svgEl, instrument, idx) {
  const keys = svgEl.querySelectorAll(`#key-${idx}`)
  const string = svgEl.querySelector(`#string-${idx}`)
  const neck = svgEl.querySelector(`#neck-${idx}`)

  for (let key of keys) {
    playAnimation(key, `pulled-${instrument}-key`)
  }
  playAnimation(string, `pulled-${instrument}-string`)
  playAnimation(neck, `pulled-${instrument}-neck`)
}

function stopNoteAnimation(svgEl, instrument, idx) {
  const keys = svgEl.querySelectorAll(`#key-${idx}`)
  const string = svgEl.querySelector(`#string-${idx}`)
  const neck = svgEl.querySelector(`#neck-${idx}`)

  for (let key of keys) {
    stopAnimation(key, `pulled-${instrument}-key`)
  }
  stopAnimation(string, `pulled-${instrument}-string`)
  stopAnimation(neck, `pulled-${instrument}-neck`)
}

function playAnimation(target, animationType) {
  if (!target) return
  const animation =
    target.querySelector('animate') || attachAnimation(target, animationType)
  animation.beginElement()
}

function stopAnimation(target, animationType) {
  if (!target) return
  const animation =
    target.querySelector('animate') || attachAnimation(target, animationType)
  animation.endElement()
}

function attachAnimation(target, animationType) {
  const animation = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'animate'
  )
  for (let attribute of INSTRUMENT_ANIMATIONS[animationType]) {
    animation.setAttributeNS(null, ...attribute)
  }

  target.appendChild(animation)
  return animation
}

function useLocaleRedirect() {
  const router = useRouter()
  const pathName = router.pathname

  const locale = getInitialLocale()

  React.useEffect(() => {
    if (locale !== 'en') router.replace(`/${locale}${pathName}`)
  })
}

export { usePreloadedInstrumentImages, animatePlayedNote, useLocaleRedirect, stopNoteAnimation }
