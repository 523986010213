import * as Sentry from '@sentry/node'

class Tuner {
  constructor() {
    this.bufferSize = 4096
    this.window = Array(3).fill(null)

    this.stableSoundSTDThreshold = 1 // hertz
    this.frequencyLowThreshold = 40 // hertz
    this.frequencyHighThreshold = 1200 // hertz
    this.volumeLowThreshold = 160 // decibel (-100 to -30), normalized to 0-255
    this.soundEffectLowThreshold = 182 // hertz
    this.soundEffectHigherThreshold = 190 // hertz

    this.onAudioProcess = this.onAudioProcess.bind(this)

    this.initGetUserMedia()
  }

  get noteStrings() {
    return this.constructor.noteStrings
  }

  get middleA() {
    return this.constructor.middleA
  }

  get semitone() {
    return this.constructor.semitone
  }

  initGetUserMedia() {
    window.AudioContext = window.AudioContext || window.webkitAudioContext
    if (!window.AudioContext) {
      return alert('AudioContext not supported')
    }

    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {}
    }

    // Some browsers partially implement mediaDevices. We can't just assign an object
    // with getUserMedia as it would overwrite existing properties.
    // Here, we will just add the getUserMedia property if it's missing.
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        // First get ahold of the legacy getUserMedia, if present
        const getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return new Promise((resolve, reject) => {
            reject(new Error('Browser not supported'))
          })
        }

        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject)
        })
      }
    }
  }

  onAudioProcess(event) {
    const frequency = this.pitchDetector.do(event.inputBuffer.getChannelData(0))
    if (frequency && this.onNoteDetected) {
      this.window.shift()
      this.window.push(frequency)

      // filter out non-stable sound
      const freqStanDeviation = standDeviation(this.window)
      const stableSound = freqStanDeviation < this.stableSoundSTDThreshold

      // filter out "String Tuned" sound effect
      const isSoundEffect =
        frequency >= this.soundEffectLowThreshold &&
        frequency <= this.soundEffectHigherThreshold

      // filter out non-guitar frequencies
      const inGuitarFreqInterval =
        frequency >= this.frequencyLowThreshold &&
        frequency <= this.frequencyHighThreshold

      // filter out low volume
      const buffer = new Uint8Array(this.analyser.frequencyBinCount)
      this.analyser.getByteFrequencyData(buffer)
      const maxVolume = Math.max(...buffer)
      const loudEnough = maxVolume > this.volumeLowThreshold

      const noteDetected =
        stableSound && inGuitarFreqInterval && loudEnough && !isSoundEffect

      const soundEnded = !frequency

      if (noteDetected || soundEnded) {
        const note = this.getNote(frequency)

        this.onNoteDetected({
          value: note,
          frequency,
        })
      }
    }
  }

  startRecord() {
    const self = this
    return navigator.mediaDevices
      .getUserMedia({ audio: { echoCancellation: true } })
      .then((stream) => {
        self.stream = stream
        self.audioContext.createMediaStreamSource(stream).connect(self.analyser)
        self.analyser.connect(self.scriptProcessor)
        self.scriptProcessor.connect(self.audioContext.destination)
        self.scriptProcessor.addEventListener(
          'audioprocess',
          self.onAudioProcess
        )
        return true
      })
  }

  init(callback, error, onNoteDetected) {
    try {
      this.onNoteDetected = onNoteDetected
      if (this.audioContext) {
        this.audioContext.resume()
      } else {
        this.audioContext = new window.AudioContext()
        this.analyser = this.audioContext.createAnalyser()
        this.scriptProcessor = this.audioContext.createScriptProcessor(
          this.bufferSize,
          1,
          1
        )
      }

      const self = this

      Aubio().then((aubio) => {
        if (!self.pitchDetector) {
          self.pitchDetector = new aubio.Pitch(
            'default',
            self.bufferSize,
            1,
            self.audioContext.sampleRate
          )
        }
        self
          .startRecord()
          .then((result) => callback(result))
          .catch((err) => error(err))
      })
    } catch (err) {
      error(err)
    }
  }

  stop() {
    try {
      this.audioContext.suspend()
      this.onNoteDetected = () => {}
      this.scriptProcessor.removeEventListener(
        'audioprocess',
        this.onAudioProcess
      )
      this.stream.getAudioTracks()[0].stop()
    } catch (err) {
      console.log('Failed to stop the stream:', err)
      Sentry.captureException(err)
    }
  }

  /**
   * get musical note from frequency
   *
   * @param {number} frequency
   * @returns {number}
   */
  getNote(frequency) {
    const note = 12 * (Math.log(frequency / this.middleA) / Math.log(2))
    return Math.round(note) + this.semitone
  }

  getOctave(note) {
    return parseInt(note / 12) - 1
  }

  getNoteName(noteInt) {
    const notes = [
      'C',
      'C♯',
      'D',
      'D♯',
      'E',
      'F',
      'F♯',
      'G',
      'G♯',
      'A',
      'A♯',
      'B',
    ]
    return notes[noteInt % 12]
  }

  /**
   * get the musical note's standard frequency
   *
   * @param note
   * @returns {number}
   */
  getStandardFrequency(note) {
    return this.middleA * Math.pow(2, (note - this.semitone) / 12)
  }

  /**
   * get closest note by frequency
   *
   * @param {array} notes
   * @param {number} frequency
   */
  getClosestNote(notes, frequency) {
    let index = null
    notes.reduce((prev, currNote, idx) => {
      const currFrequency = this.getStandardFrequency(currNote)
      if (Math.abs(currFrequency - frequency) < Math.abs(prev - frequency)) {
        index = idx
        return currFrequency
      } else {
        return prev
      }
    }, Number.MAX_SAFE_INTEGER)
    return notes[index]
  }

  /**
   * get cents difference between given frequency and musical note's standard frequency
   *
   * @param {number} frequency
   * @param {number} note
   * @returns {number}
   */
  getCents(frequency, note) {
    return Math.floor(
      (1200 * Math.log(frequency / this.getStandardFrequency(note))) /
        Math.log(2)
    )
  }
}

function standDeviation(arr) {
  let counter = 0
  const sum = arr.reduce((total, curr) => {
    if (typeof curr === 'number') {
      counter++
      return total + curr
    } else {
      return total
    }
  }, 0)
  const mean = sum / counter
  const numerator = arr.reduce((total, curr) => {
    if (typeof curr === 'number') {
      return total + Math.pow(curr - mean, 2)
    } else {
      return total
    }
  }, 0)
  const denominator = counter
  return Math.sqrt(numerator / denominator)
}

Tuner.noteStrings = [
  'C',
  'C♯',
  'D',
  'D♯',
  'E',
  'F',
  'F♯',
  'G',
  'G♯',
  'A',
  'A♯',
  'B',
]

Tuner.middleA = 440
Tuner.semitone = 69

export default Tuner
