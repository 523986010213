import React from 'react'
import styles from './ModeSwitch.module.scss'
import HeadphoneSvg from '../public/icons/Headphone.svg'
import MicrophoneSvg from '../public/icons/Microphone.svg'
import useTranslation from '../translations/useTranslation'

function ModeSwitch({ microphone, handleByEar, handleAutoTune }) {
  const { t } = useTranslation()
  const classes = [styles.container]
  if (microphone) classes.push(styles.microphoneSelected)

  const byEarClasses = [styles.switchOption]
  const microphoneClasses = [styles.switchOption]
  if (!microphone) byEarClasses.push(styles.activeOption)
  else microphoneClasses.push(styles.activeOption)

  const handleEarClick = () => {
    if (microphone) handleByEar()
  }

  const handleAutoClick = () => {
    if (!microphone) handleAutoTune()
  }

  return (
    <div className={classes.join(' ')}>
      <div className={styles.switchBody}>
        <div className={styles.switchOptions}>
          <div className={byEarClasses.join(' ')} onClick={handleEarClick}>
            <HeadphoneSvg className={styles.headphoneImg} />
            {t('byEar')}
          </div>
          <div
            className={microphoneClasses.join(' ')}
            onClick={handleAutoClick}
          >
            <MicrophoneSvg className={styles.microphoneImg} />
            {t('auto')}
          </div>
          <div className={styles.switchCircle}></div>
        </div>
      </div>
    </div>
  )
}

export default ModeSwitch
