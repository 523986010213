import React, { useState } from 'react'
import styles from './SelectTuning.module.scss'
import useTranslation from '../translations/useTranslation'
import { instruments } from '../instruments'

function Option({ tuning, active }) {
  const classes = [styles.option]
  if (active) classes.push('selected')

  return (
    <li className={classes.join(' ')}>
      <span className={styles.optionName}>{tuning[0]}</span>
      <span className={styles.optionNotes}>{tuning[1]}</span>
    </li>
  )
}

export default function SelectTuning({ currentInstrument }) {
  const [selected] = useState(0)
  const { t } = useTranslation()

  const { tunings } = instruments.find(
    (instrument) => instrument.name === currentInstrument
  )

  return (
    <div className={styles.container}>
      <span className={styles.heading}>{t('selectTuning')}</span>
      <ul className={styles.tuningsList}>
        {tunings.map((tuning, i) => (
          <Option key={tuning[0]} tuning={tuning} active={selected == i} />
        ))}
      </ul>
      <span className={styles.comingSoon}>{t('comingSoon')}</span>
    </div>
  )
}
