import ShadowCard from "../components/ShadowCard";
import Tuner from "../components/Tuner";
import TunerHome from "../components/TunerHome";
import styles from "../components/Page.module.scss";
import useTranslation from "../translations/useTranslation";
import AffiliateLinkBanner from "./AffiliateLinkBanner";
import ChordChordBanner from "./ChordChordBanner";
import SOBanner from "./SOBanner";

const SHOW_SO_BANNER = process.env.NEXT_PUBLIC_SHOW_SO_BANNER === "true";

function Page(props) {
  const { pageData } = props;
  const { t } = useTranslation();
  console.log('pageData', pageData)

  return (
    <>
      <ShadowCard>
        {props.instrument ? (
          <Tuner instrument={props.instrument} />
        ) : (
          <TunerHome />
        )}
      </ShadowCard>

      <ChordChordBanner></ChordChordBanner>

      <h2 className={styles.heading}>{t("howToUseHeader")}</h2>
      <ShadowCard>
        <div className={styles.textContainer}>
          {t("howToUseText", { pageData })}
        </div>
      </ShadowCard>
    </>
  );
}

export default Page;
