import React from 'react'
import styles from './TunerHome.module.scss'
import BigInstrumentSelector from './BigInstrumentSelector'
import useTranslation from '../translations/useTranslation'
export default function TunerHome() {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.h1text}>{t('onlineGuitarTuner')}</h1>
        <h2 className={styles.h2text}>
          {t('tuneYourInstruments')} <br />
          <span className={styles.smaller}>{t('withMicrophoneOrByEar')}</span>
        </h2>
      </div>
      <BigInstrumentSelector />
    </div>
  )
}
