import React from 'react'
import styles from './AffiliateLinkBanner.module.scss'
import dynamic from 'next/dynamic'
import ReactStars from 'react-stars'

const DynamicPlayer = dynamic(
  () => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player),
  { ssr: false }
)

const MarketingBlock = () => {
  return (
    <div className={styles.marketingBlock}>
      <table>
        <tr>
          <th className={styles.ratingColumn}>Rating</th>
          <th>Brand</th>
          <th className={styles.priceColumn}>Price</th>
          <th>Website</th>
        </tr>
        <tr>
          <td className={styles.ratingColumn}><ReactStars edit={false} size={16} count={5} value={4.8} /></td>
          <td>Guitareo</td>
          <td className={styles.priceColumn}>$20 / month</td>
          <td><FormButton primary={true} text='1 MONTH FREE' link='https://guitareo.sjv.io/c/1289539/1275835/14650' /></td>
        </tr>
        {/* <tr>
          <td className={styles.ratingColumn}><ReactStars edit={false} size={16} count={5} value={4.5} /></td>
          <td>JamPlay</td>
          <td className={styles.priceColumn}>$19.95 month</td>
          <td><FormButton text='Visit Course' link='https://prf.hn/click/camref:1100ljeZ8' /></td>
        </tr> */}
        <tr>
          <td className={styles.ratingColumn}><ReactStars edit={false} size={16} count={5} value={4.2} /></td>
          <td>ChordChord</td>
          <td className={styles.priceColumn}>$12 / month</td>
          <td><FormButton text='7 Days Free' link='https://chordchord.com/generator?fpr=nick16' /></td>
        </tr>
      </table>
    </div>
  )
}

const FormButton = ({ link, text, primary }) => {
  const style = {}
  const buttonClassName = primary ? styles.buttonPrimary : styles.button

  return (
    <div className={styles.buttonContainer}>
      <ConditionalWrapper
        condition={primary}
        wrapper={children => <div className={styles.rippleEffect}>{children}</div>}
      >
        <a href={link} className={buttonClassName} style={style} target="_blank" rel='nofollow noopener'>
          {text}
        </a>
        <span className={styles.ripple} style={{ '--i': 0 }}></span>
      </ConditionalWrapper>
      
    </div>
  )
}

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

export default function AffiliateLinkBanner() {
  return (
    <section className={styles.container}>
      <div className={styles.banner}>
        <h2 className={styles.tunedUpText}>
          Tuned up? Now learn to play the songs you love!
        </h2>
        <div className={styles.grid}>
          <div className={styles.playerContainer}>
            <DynamicPlayer
              src='/guitar-player.json'
              background='transparent'
              speed='1'
              loop={true}
              autoplay={true}
            />
          </div>

          <MarketingBlock />
        </div>
      </div>
    </section>
  )
}
